import * as React from 'react'
import { graphql, PageProps } from 'gatsby'

import { Seo } from 'components/helpers'
import { DefaultLayout } from 'components/layouts'

import MenuShowView from 'views/MenuShow'

interface IndexPageProps extends PageProps {
  pageContext: {
    prevFood: string | false
    nextFood: string | false
    slug: string
  }
  data: {
    food: Food
    allApiUnits: {
      nodes: []
    }
    allApiCategories: {
      nodes: []
    }
  }
}

const MenuShowPage: React.FC<IndexPageProps> = ({ data, pageContext }) => {
  const { food, allApiCategories, allApiUnits } = data

  return (
    <DefaultLayout>
      <Seo title={`${food.title} | Pizzas`} />
      <MenuShowView
        food={food}
        categories={allApiCategories.nodes}
        units={allApiUnits.nodes}
        pageContext={pageContext}
      />
    </DefaultLayout>
  )
}

export const query = graphql`
  query FoodQuery($slug: String) {
    food: apiFoods(slug: { eq: $slug }) {
      slug
      title
      type {
        id
        title
      }
      sizes
      body
      image
    }

    allApiCategories {
      nodes {
        id
        slug
        title
        body
        icon
        util
        image
      }
    }

    allApiUnits(sort: { order: DESC, fields: id }) {
      nodes {
        id
        type
        schedule
        phone
        address
        neighborhood
        image
      }
    }
  }
`

export default MenuShowPage
