import * as React from 'react'
import { Container, Row, Col } from 'react-awesome-styled-grid'

import { Block, Carousel, Title, Text } from 'components/shared'
import { Ribbon } from 'components/helpers'
import { Category, Unit, PizzaDetails } from 'components/app'
import { CategoryProps } from 'components/app/Category'
import { UnitProps } from 'components/app/Unit'

interface ViewProps {
  food: Food
  categories?: CategoryProps[]
  units?: UnitProps[]
  pageContext: {
    prevFood: string | false
    nextFood: string | false
    slug: string
  }
}

const FoodShowView: React.FC<ViewProps> = ({
  food,
  categories = [],
  units = [],
  pageContext: { prevFood, nextFood }
}) => (
  <>
    <Block tag="section" paddingTop={{ xxxs: 'size64', sm: 'size88' }}>
      <Block tag="article" marginBottom="size64">
        <Container>
          <Text
            textAlign="center"
            fontSize={14}
            secondColor="brown800"
            bgColor="yellow400"
            marginBottom="size48"
          >
            <span>
              CUIDADO! As fotos abaixo causam uma vontade incontrolável de comer
              uma Lasca de Pizza.
            </span>
          </Text>

          <PizzaDetails
            type={food.type.title}
            title={food.title}
            body={food.body}
            sizes={food.sizes}
            image={food.image}
            prevPizza={prevFood}
            nextPizza={nextFood}
          />
        </Container>
      </Block>

      <Block tag="footer" marginBottom="size48">
        <Container>
          <Row justify="center">
            <Col xs={12} sm={10} md={8}>
              <Block marginBottom="size64">
                <Title
                  level="h1"
                  appearance="menu"
                  secondColor="brown800"
                  textAlign="center"
                  lineHeight={1}
                  uppercase
                >
                  <span>Confira nossos sabores:</span> Tradicionais ou Gourmet
                </Title>
              </Block>
            </Col>
          </Row>
        </Container>

        <Carousel
          appearance="categories"
          swiper={{
            effect: 'coverflow',
            coverflowEffect: {
              rotate: 50,
              stretch: 0,
              depth: 100,
              modifier: 1,
              slideShadows: false
            },
            initialSlide: 0,
            observer: true,
            watchOverflow: true,
            simulateTouch: false,
            autoplay: {
              delay: 4000
            },
            centeredSlides: true,
            centerInsufficientSlides: true,
            loop: true,
            spaceBetween: 64,
            slidesPerView: 3,
            breakpoints: {
              0: {
                slidesPerView: 2
              },
              1199: {
                slidesPerView: 3
              }
            }
          }}
        >
          {categories.map((category) => (
            <Category key={category.id} {...category} />
          ))}
        </Carousel>
      </Block>
    </Block>

    <Block height="size64" bgColor="white" position="relative" zIndex={2}>
      <Ribbon color="brown800" slogan="primary" angle={0.4} delay="-12s" />
    </Block>

    <Block tag="section" paddingTop="size64" paddingBottom="size88">
      <Container>
        <Block tag="header" textAlign="center" marginBottom="size48">
          <Text fontSize={14} uppercase>
            Unidades
          </Text>
          <Title size="h3" uppercase>
            Faça-nos uma visita
          </Title>
        </Block>
        <Block tag="article">
          <Carousel
            swiper={{
              initialSlide: 0,
              observer: true,
              watchOverflow: true,
              simulateTouch: false,
              autoplay: {
                delay: 4000
              },
              spaceBetween: 32,
              slidesPerView: 2,
              breakpoints: {
                0: {
                  spaceBetween: 0,
                  slidesPerView: 1
                },
                962: {
                  spaceBetween: 32,
                  slidesPerView: 2
                }
              }
            }}
          >
            {units.map((unit) => (
              <Unit key={unit.id} {...unit} />
            ))}
          </Carousel>
        </Block>
      </Container>
    </Block>
  </>
)

export default FoodShowView
